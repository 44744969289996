.bloc-clients {
  @include grid();
  width: 100%;
  min-height: 100px;
  padding: 50px $default-grid-padding 100px;

  @include media($until: small) {
    padding: 30px $default-grid-padding-mobile 100px;
  }

  &__wrapper {
    @include grid-col(10);
    margin: 0 auto;
    display: flex;

    @include media($until: small) {
      width: 100%;
      max-width: none;
      margin: 0;
      flex: none;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      @include media($until: small) {
        justify-content: flex-start;
      }

      li {
        font-family: "Montserrat Thin";
        margin: 0 20px;
        font-size: 45px;
        opacity: 0.4;
        line-height: 1.05;
        position: relative;
        transition: opacity 0.3s $quad-ease-out;

        @include media($until: small) {
          margin: 0;
          margin-right: 40px;
        }

        &:hover {
          opacity: 1;
        }
        
        &:last-of-type {
          &::after {
            display: none;
          }
        }

        &::after {
          content: '';
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: $white;
          position: absolute;
          right: -20px;
          bottom: 5px;
        }
      }
    }
  }
}