.bloc-credits {
  @include grid();
  margin-top: 160px;
  margin-bottom: 110px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.6s $quad-ease-out;

  &.visible {
    opacity: 1;
  }

  @include media($until: small) {
    margin-top: 50px;
    margin-bottom: 30px;
    opacity: 1;
  }

  &__wrapper {
    margin: 0 auto;

    .list {
      display: flex;

      @include media($until: small) {
        flex-direction: column;
      }

      .item {
        width: 250px;
        padding-left: 30px;
        margin-top: 15px;

        @include media($until: small) {
          width: 180px;
          padding-left: 0;
        }

        .title {
          font-family: "Montserrat";
          font-size: 15px;
          display: block;
          font-weight: bold;

          @include media($until: small) {
            font-size: 13px;
          }
        }
    
        .text {
          font-family: "Montserrat Light";
          font-size: 15px;
          display: block;
          line-height: 1.4;

          @include media($until: small) {
            font-size: 13px;
          }
        }
      }
    }
  }
}