.bloc-projects-list {
  @include grid();
  width: 100%;
  padding-bottom: 8px;
  padding-left: calc(#{$default-grid-padding} - 8px);
  padding-right: calc(#{$default-grid-padding} - 8px);
  display: flex;
  flex-wrap: wrap;

  @include media($until: small) {
    padding-left: calc(#{$default-grid-padding-mobile} - 8px);
    padding-right: calc(#{$default-grid-padding-mobile} - 8px);
  }

  .project-item {
    width: 100%;
    padding: 6px 8px;
    position: relative;
    opacity: 0;
    transition: opacity 0.6s $quad-ease-out;

    &.visible {
      opacity: 1;
    }

    @include media($until: small) {
      opacity: 1;
    }

    &.half-size {
      width: 50%;

      @include media($until: small) {
        width: 100%;
      }

      a {
        padding-bottom: 57%;
      }
    }

    a {
      display: inline-block;
      width: 100%;
      height: 0;
      padding-bottom: 41%;
      position: relative;
      overflow: hidden;

      @include media($until: small) {
        padding-bottom: 57%;
      }

      .desktop &:hover {
        .projet-item-overlay {
          opacity: 0.4;
        }

        .project-item-img {
          transform: scale(1.1);
        }
      }

      .project-item-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
        transition: transform 6s $quad-ease-out;
      }

      .projet-item-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 0;
        z-index: 1;
        transition: opacity 0.3s $quad-ease-out;
      }

      .project-item-content {
        position: absolute;
        bottom: 0;
        z-index: 2;
        padding: 40px;

        @include media($until: small) {
          padding: 15px;
        }

        .client {
          font-family: "Montserrat Thin";
          font-size: 15px;
          display: block;
          text-transform: uppercase;
          max-width: 450px;

          @include media($until: small) {
            font-size: 14px;
            margin-bottom: 5px;
          }
        }

        .title {
          font-family: "Montserrat Light";
          font-size: 35px;
          display: block;
          text-transform: uppercase;
          max-width: 440px;

          @include media($until: small) {
            font-size: 18px;
          }
        }
      }
    }
  }
}