.app-header {
  @include grid();
  width: 100vw;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 30px $default-grid-padding;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-between;
  z-index: 1;

  &.visible {
    .main-menu,
    .logo-link {
      opacity: 1 !important;
    }
  }

  &.menu-opened {
    .menu-container {
      opacity: 1;
      pointer-events: auto;
    }

    .menu-burger {
      .n-2 {
        opacity: 0;
      }

      .n-1 {
        width: 40px;
        transform: translateY(9px) rotate(45deg);
      }

      .n-3 {
        width: 40px;
        transform: translateY(-8px) rotate(-45deg);

        .safari & {
          transform: translateY(-7px) rotate(-45deg);
        }
      }
    }
  }

  @include media($until: small) {
    padding: 20px;
  }

  .template-home & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media($until: small) {
      top: 0;
      left: 0;
      transform: none;
    }
  } 
  
  .template-project & {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  } 

  .logo-link {
    display: inline-block;

    .template-home & {
      transition: opacity 0.6s $quad-ease-out;
      opacity: 0;
      transition-delay: 0.6s;
    }

    @include media($until: small) {
      z-index: 11;
      opacity: 1 !important;
    }

    img {
      width: 180px;
      height: auto;

      @include media($until: small) {
        width: 100px;
      }
    }
  }

  .menu-container {
    @include media($until: small) {
      opacity: 0;
      pointer-events: none;
      position: fixed;
      background-color: $black;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      display: flex;
      justify-content: flex-end;
      padding: 50px 30px;
      flex-direction: column;
      z-index: 10;
      transition: opacity 0.3s $quad-ease-out;
      padding-bottom: 130px;
    }
  }

  .main-menu {
    display: flex;

    .template-home & {
      transition: opacity 0.6s $quad-ease-out;
      opacity: 0;
      transition-delay: 2s;
    }

    @include media($until: small) {
      flex-direction: column;
      width: 100%;
      opacity: 1 !important;
    }

    li {
      margin-left: 20px;

      @include media($until: small) {
        margin-left: 0;
      }

      a {
        text-transform: uppercase;                                      
        position: relative;
        color: $white-light;

        @include media($until: small) {
          font-size: 35px;
        }
    
        &::before {
          content: '';
          transform: scaleX(0);
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -2px;
          background: $white-light;
          height: 1px;
          transform-origin: left;
          transition: transform 0.3s $quad-ease-out;
        }
      }

      .desktop &:hover,
      .desktop &.active {
        a:before {
          transform: scaleX(1);
        }
      }
    }
  }

  .header-text {
    display: none;
    width: 100%;
    margin-top: 20px;

    @include media($until: small) {
      display: block;
    }
  }

  .header-footer {
    display: flex;
    min-height: 40px;
    height: 70px;
    align-items: center;
    padding: 10px $default-grid-padding-mobile;
    justify-content: space-between;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;

    @include media($until: small) {
      display: flex;
    }
  }

  .header-socials {
    display: flex;
    width: 33%;
    justify-content: center;

    @include media($until: small) {
      width: 50%;
    }

    li {
      margin: 0 10px;
      transition: opacity 0.3s $quad-ease-out;

      a {
        color: $black;
      }

      @include media($until: small) {
        margin: 0;
        font-size: 12px;
        margin-right: 5px;
      }

      &:hover {
        opacity: 0.4;
      }
    }
  }

  .header-credits {
    width: 33%;
    text-align: right;
    color: #929292;

    @include media($until: small) {
      font-size: 12px;
      width: 50%;
    }

    a {
      transition: opacity 0.3s $quad-ease-out;

      &:hover {
        opacity: 0.4;
      }
    }
  }

  .menu-burger {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    transition: background 0.3s $quad-ease-out;
    z-index: 21;
    cursor: pointer;

    @include media($until: small) {
      display: flex;
    }

    .n-1,
    .n-2,
    .n-3 {
      background: $white;
      height: 3px;
      width: 40px;
      border-radius: 20px 20px;
      transition: opacity 0.3s $quad-ease-out, transform 0.5s $quad-ease-out;
    }

    .n-2 {
      margin-top: 5px;
    }

    .n-3 {
      margin-top: 5px;
    }
  }

  .home-mobile-link-container {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    display: none;

    @include media($until: small) {
      .template-home & {
        display: flex;
      }
    }
  }

  .home-mobile-link {
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 30px;
    color: $white;
    z-index: 1;
    pointer-events: auto;
    font-size: 16px;

    .arrow {
      bottom: 50px;
      left: 50%;
      margin-right: 10px;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid $white;
      border-left: 2px solid $white;
      transform: translateX(-50%) rotate(-45deg);
    }
  }
}