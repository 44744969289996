.template-home {
  height: 100vh;
  overflow: hidden;

  .main {
    height: 100vh;

    .app-container {
      height: 100vh;
    }
  }
}