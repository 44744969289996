@font-face{
  font-family: "Montserrat";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url("/assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face{
  font-family: "Montserrat Light";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url("/assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face{
  font-family:"Montserrat Thin";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url("/assets/fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
}

@font-face{
  font-family: "Montserrat Bold";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url("/assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}