.bloc-intro {
  @include grid();
  margin-top: 200px;
  margin-bottom: 170px;
  opacity: 0;
  transition: opacity 0.6s $quad-ease-out;

  &.visible {
    opacity: 1;
  }

  @include media($until: small) {
    margin-top: 70px;
    margin-bottom: 70px;
    opacity: 1;
  }

  &__wrapper {
    @include grid-col(4.5);
    margin: 0 auto;

    @include media($until: small) {
      @include grid-col(10);
      margin: 0 auto;
    }

    .client {
      font-family: "Montserrat Thin";
      font-size: 15px;
      display: block;
      text-transform: uppercase;
    }

    .title {
      font-family: "Montserrat Light";
      font-size: 35px;
      display: block;
      text-transform: uppercase;

      @include media($until: small) {
        font-size: 23px;
        margin-top: 10px;
      }
    }

    .text {
      font-family: "Montserrat Thin";
      font-size: 17px;
      display: block;
      margin-top: 25px;
      line-height: 1.4;

      strong,
      b {
        font-family: "Montserrat";
      }

      a {
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.4);
        transition: opacity 0.3s $quad-ease-out;
        
        &:hover {
          opacity: 0.7;
        }
      }

      @include media($until: small) {
        font-size: 13px;
      }
    }
  }
}