.bloc-contact {
  @include grid();
  margin-top: 120px;
  padding-bottom: 170px;

  @include media($until: small) {
    padding: 0 $default-grid-padding-mobile;
    margin-top: 50px;
    padding-bottom: 100px;
  }
  
  &__wrapper {
    @include grid-col(8);
    margin: 0 auto;
    display: flex;

    @include media($until: small) {
      max-width: none;
      flex: none;
      width: 100%;
      flex-direction: column-reverse;
    }
  }

  &__infos {
    font-family: "Montserrat Thin";
    width: 37.5%;
    font-size: 17px;
    padding-right: 30px;
    margin-top: 20px;
    line-height: 1.4;

    strong,
    b {
      font-family: "Montserrat";
    }

    a {
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.4);
      transition: opacity 0.3s $quad-ease-out;
      
      &:hover {
        opacity: 0.7;
      }
    }

    @include media($until: small) {
      width: 100%;
      margin-top: 50px;
      padding-right: 0;
      text-align: center;
    }
  }

  &__form {
    width: 62.5%;

    @include media($until: small) {
      width: 100%;
    }

    .alert {
      font-size: 14px;
      margin-top: 22px;
      margin-bottom: 30px;
    }
    
    form {
      display: flex;
      flex-direction: column;
    }

    .honeypot {
      position: absolute;
      left: -9999px;
    }

    .field {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      label {
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 10px;

        @include media($until: small) {
          font-size: 16px;
        }
      }

      input {
        font-family: "Montserrat Light";
        border: 1px solid $grey;
        padding: 12px 20px;
        font-size: 16px;
      }

      textarea {
        font-family: "Montserrat Light";
        border: 1px solid $grey;
        height: 170px;
        padding: 12px 20px;
        font-size: 16px;
      }
    }

    .submit {
      font-family: "Montserrat Light";
      text-transform: uppercase;
      align-self: flex-end;
      font-size: 30px;
      cursor: pointer;

      @include media($until: small) {
        font-size: 22px;
      }
    }
  }
}