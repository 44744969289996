.page-transition {
  z-index: 2;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;

  &__first-banner {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $black;
    transform: translateY(100%);
  }
}