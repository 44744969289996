.bloc-free-section {
  @include grid();
  margin-top: 170px;
  margin-bottom: 200px;

  @include media($until: small) {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  &__wrapper {
    @include grid-col(10);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    @include media($until: small) {
      @include grid-col(12);
    }

    .fs-bloc {
      opacity: 0;
      transition: opacity 0.6s $quad-ease-out;

      &.visible {
        opacity: 1;
      }

      @include media($until: small) {
        opacity: 1;
      }
    }

    .fs-bloc-heading {
      width: 100%;

      @include media($until: small) {
        width: 100%;
        margin: 0 auto;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 30px;
        text-transform: uppercase;
        line-height: 1.5;
        margin: 15px 0;
        padding: 0 7px;

        @include media($until: small) {
          font-size: 22px;
          margin: 5px auto;
          padding: 0;
        }
      }
    }

    .fs-bloc-text,
    .fs-bloc-customtext,
    .fs-bloc-markdown {
      width: 100%;
      padding: 50px 0;
      font-family: "Montserrat Thin";
      font-size: 17px;
      display: block;
      line-height: 1.4;

      @include media($until: small) {
        width: 100%;
        margin: 0 auto;
        padding: 50px 0;
      }

      .text-wrapper {
        @include grid-col(6, $columns: 10);

        @include media($until: small) {
          margin: 0;
          max-width: none;
          width: 100%;
        }

        strong,
        b {
          font-family: "Montserrat";
        }

        a {
          border-bottom: 1px solid rgba($color: $white, $alpha: 0.4);
          transition: opacity 0.3s $quad-ease-out;
          
          &:hover {
            opacity: 0.7;
          }
        }

        p {
          margin-bottom: 20px;

          @include media($until: small) {
            font-size: 13px;
          }
  
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .fs-bloc-image,
    .fs-bloc-customimage {
      width: 100%;
      margin: 7px;

      &[data-ratio="half"] {
        width: calc(50% - 14px);
        margin: 7px;
        display: inline-block;
        height: 0;
        position: relative;
        padding-bottom: 22%;

        @include media($until: small) {
          width: calc(50% - 6px);
          margin: 3px;
        }

        figure {
          position: absolute;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      figure {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .fs-bloc-embedVideo {
      width: 100%;

      &[data-ratio="half"] {
        width: calc(50% - 14px);
        margin: 7px;
        display: inline-block;
        height: 0;
        position: relative;
        padding-bottom: 22%;

        @include media($until: small) {
          width: calc(50% - 6px);
          margin: 3px;
        }

        video {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      video {
        width: 100%;
        object-fit: cover;
        height: auto;
      }
    }

    .fs-bloc-blank {
      height: 160px;
      width: 100%;

      @include media($until: small) {
        height: 60px;
      }
    }

    .fs-bloc-gallery {
      display: none;
    }
  }
}